.fade-in {
  animation: fade-in 0.25s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 0.25s ease forwards;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Edited default styles */

.rnc__notification-container--bottom-right {
  width: 325px;
  position: absolute;
  pointer-events: all;
}
.rnc__notification-container--center {
  top: 20px;
  height: 100%;
  pointer-events: none;
}
.rnc__notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}
.rnc__notification-container--mobile-bottom {
  pointer-events: all;
  position: absolute;
}
.rnc__notification-container--mobile-bottom {
  @apply -mb-1;
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;
}
.rnc__notification-item--default {
  @apply border-l-8 border-blue-800 bg-blue-700;
}
.rnc__notification-item--default .rnc__notification-close {
  @apply bg-blue-700;
}
.rnc__notification-item--success {
  @apply border-l-8 border-green-800 bg-green-700;
}
.rnc__notification-item--success .rnc__notification-close {
  @apply bg-green-700;
}
.rnc__notification-item--danger {
  @apply border-l-8 border-red-800 bg-red-700;
}
.rnc__notification-item--danger .rnc__notification-close {
  @apply bg-red-700;
}
.rnc__notification-item--warning {
  @apply border-l-8 border-yellow-800 bg-yellow-700;
}
.rnc__notification-item--warning .rnc__notification-close {
  @apply bg-yellow-700;
}
.rnc__base {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.rnc__notification-item {
  @apply shadow rounded-sm mb-4;
  display: flex;
  position: relative;
  cursor: pointer;
}
.rnc__notification-item .rnc__notification-title {
  @apply my-1;
  font-weight: 800;
  font-size: 14px;
}
.rnc__notification-item .rnc__notification-message {
  @apply text-sm;
  max-width: calc(100% - 15px);
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
.rnc__notification-item .rnc__notification-content {
  @apply px-2 py-4;
  display: inline-block;
  width: 100%;
}
.rnc__notification-item .rnc__notification-close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
}
.rnc__notification-item .rnc__notification-close::after {
  content: '\D7';
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  left: 50%;
  top: 50%;
}
.notification-container-mobile-bottom .rnc__notification-item,
.notification-container-mobile-bottom .rnc__notification-parent {
  max-width: 100%;
  width: 100%;
}
.notification-container-bottom-right .rnc__notification-parent,
.notification-container-top-right .rnc__notification-parent {
  margin-left: auto;
}
.notification-container-mobile-bottom .rnc__notification-parent,
.notification-container-mobile-top .rnc__notification-parent {
  margin-left: auto;
  margin-right: auto;
}
