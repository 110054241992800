@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.11') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Regular.woff?v=3.11') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('https://rsms.me/inter/font-files/Inter-Medium.woff2?v=3.11') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Medium.woff?v=3.11') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('https://rsms.me/inter/font-files/Inter-Bold.woff2?v=3.11') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Bold.woff?v=3.11') format('woff');
  font-display: swap;
}

/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

:root {
  --input-background: theme('colors.gray.800');
  --input-background-darker: theme('colors.gray.900');
  --input-background-lighter: theme('colors.gray.700');
}

body {
  @apply font-sans text-gray-300 bg-gray-800;
  box-sizing: border-box;
  height: 100vh;
}

/* improve firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.gray.500') transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-white font-display font-bold leading-snug;
}

h1 {
  @apply text-2xl mb-2;
}
@screen sm {
  h1 {
    @apply text-3xl mb-3;
  }
}

h2 {
  @apply text-xl mb-1;
}
@screen sm {
  h2 {
    @apply text-2xl mb-2;
  }
}

h3 {
  @apply text-lg;
}
@screen sm {
  h3 {
    @apply text-xl;
  }
}

h4 {
  @apply text-lg;
}

strong {
  @apply text-white;
}

* {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

::selection {
  text: white;
  background: rgba(255, 255, 255, 0.35);
}

:focus:not(.focus-visible) {
  outline: none;
}

:focus-visible {
  outline: 2px dotted currentColor;
}

input:not([type='radio']):not([type='checkbox']) {
  @apply h-10;
}

/*
  Chrome background color override hack
  https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px var(--input-background) inset;
  -webkit-text-fill-color: theme('colors.gray.300');
}

.change-input-background {
  --input-background: theme('colors.gray.700');
  --input-background-darker: theme('colors.gray.800');
  --input-background-lighter: theme('colors.gray.600');
}

.reset-input-background {
  --input-background: theme('colors.gray.800');
  --input-background-darker: theme('colors.gray.900');
  --input-background-lighter: theme('colors.gray.700');
}

.input-background {
  background: var(--input-background);
}

input:not([type='radio']):not([type='checkbox']),
textarea {
  -webkit-appearance: none; /* disable box shadow in iOS Safari */
  min-width: 12rem;
  max-width: 16rem;
  transition: border-color 0.2s ease !important;
  background-color: var(--input-background);
  border-color: var(--input-background);
  caret-color: white;
  @apply inline-block border-2 pl-3 rounded;
}

input[type='password'].weak:focus {
  @apply border-yellow-400 !important;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.8;
  @apply text-gray-400;
}

textarea {
  @apply p-3;
}

input:not([type='radio']):not([type='checkbox']):focus,
textarea:focus {
  @apply border-2 border-blue-400;
  outline: none !important;
}

input[type='radio'] {
  @apply min-w-0 p-0 h-auto sr-only;
}

input[type='radio']:disabled + label,
input[type='checkbox']:disabled + label {
  opacity: 0.6;
}

input[type='radio'] + label::before {
  content: '';
  bottom: 0.125rem;
  transition: all 0.2s ease;
  @apply w-4 h-4 rounded-full inline-block bg-transparent border-2 border-gray-200 relative mr-2 align-middle;
}

input[type='radio']:checked + label::before {
  @apply border-blue-400 bg-gray-200;
  box-shadow: 0 0 0 0.1875rem theme('colors.gray.700') inset;
}

input[type='checkbox'] {
  @apply sr-only;
}

input[type='checkbox'] + label {
  padding-left: 1.25rem;
  @apply align-middle select-none relative;
}

input[type='checkbox'].focus-visible + label::before {
  @apply border-blue-400;
}

input[type='checkbox'] + label::before {
  content: '';
  top: 0.125rem;
  @apply w-4 h-4 rounded-sm border-2 border-gray-200 align-middle text-center leading-none left-0 absolute;
}

input[type='checkbox'] + label::after {
  @apply border-b-2 border-r-2 border-gray-200;
  position: absolute;
  top: 8px;
  left: 8px;
  transform: translate(-2.5px, -3.5px) rotate(35deg) skewX(5deg);
  width: 6px;
  height: 9px;
}

input[type='checkbox']:checked + label::after {
  content: '';
}

button.primary,
button.secondary,
button.full-size,
a.button {
  @apply inline-flex items-center justify-center select-none text-sm font-medium px-4 h-8 text-white rounded align-text-bottom;
}

button:not(.tertiary),
[role='button'] {
  @apply cursor-default;
}

button.primary svg,
button.secondary svg,
button.full-size svg,
a.button svg {
  @apply mr-1 -ml-1;
}

button.primary,
a.button.primary {
  @apply bg-blue-700 border-blue-600 border-2;
}

button.primary:hover,
button.primary.instant-touch[touched],
a.button.primary:hover,
a.button.primary.instant-touch[touched] {
  @apply bg-blue-600;
}

button.secondary {
  @apply border-2 border-gray-600;
}

button.secondary:hover,
button.secondary.instant-touch[touched] {
  background: rgba(255, 255, 255, 0.1);
}

button.tertiary,
a {
  @apply text-blue-300;
}

button.tertiary:hover,
a:hover,
button.tertiary.instant-touch[touched],
a.instant-touch[touched] {
  @apply text-blue-200;
}

button.red {
  @apply bg-red-700 border-red-600 border-2;
}

button.red:hover,
button.red.instant-touch[touched] {
  @apply bg-red-600;
}

button.green {
  @apply bg-green-700 border-green-600 border-2;
}

button.green:hover,
button.green.instant-touch[touched] {
  @apply bg-green-600;
}

button:focus:not(:focus-visible) {
  outline: none;
}

button.tertiary:disabled {
  @apply text-gray-300 text-opacity-75 pointer-events-none;
}

fieldset {
  @apply mb-6;
}

legend {
  @apply mb-2 text-sm font-medium uppercase select-none;
}

@screen md {
  fieldset {
    @apply mx-6;
  }

  legend {
    @apply -ml-6;
  }
}
